<template>
  <div></div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";

export default {
  mixins: [baseViewMixin, currentUserMixin],
  mounted() {
    this.internalLogout();
    if (this.$Settings.all.excecutionMode == "FRONT") {
      this.gotoView("begin");
    }

    if (this.$Settings.all.excecutionMode == "WF") {
      this.gotoView("login");
    }

    setTimeout(() => (window.location = window.location.href), 1000);
  },
};
</script>

<style lang="scss" scoped></style>
